import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navigation from "./components/Navigation";
import Welcome from "./components/Welcome";
import Services from "./components/Services";
import Contact from "./components/Contact";
import theme from "./theme";

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: "#db941d",
    height: "40px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  footerText: {
    color: "#ffffff",
    padding: "0.5em",
    fontSize: "1em",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
}));
function App() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navigation value={value} setValue={setValue} />
        <Switch>
          <Route exact path="/" render={(props) => <Welcome />} />
          <Route
            exact
            path="/services"
            render={(props) => <Services value={value} setValue={setValue} />}
          />
          <Route
            exact
            path="/contact"
            render={(props) => <Contact value={value} setValue={setValue} />}
          />
        </Switch>
        <footer className={classes.footer}>
          <Grid
            container
            direction={matchesSM ? "column" : "row"}
            justifyContent={matchesSM ? "center" : "space-between"}
            align="center"
          >
            <Typography variant="h4" className={classes.footerText}>
              Copyright Pioneer Property Management 2022
            </Typography>
            <Typography variant="h4" className={classes.footerText}>
              Website Design by{" "}
              <a
                href="mailto:sabrinatessierdev@gmail.com"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                Sabrina Tessier
              </a>
            </Typography>
          </Grid>
        </footer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
