import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
/*Images*/
import banner from "../assets/servicesbanner.jpeg";
import whyusbanner from "../assets/whyusbanner.jpeg";
import cleanbedroom from "../assets/cleanbedroom.jpg";
import landscape from "../assets/landscape2.jpg";
import propertymanagement from "../assets/propertymanagement.jpeg";
import modernkitchen from "../assets/modernkitchen.jpg";
import ratesbanner from "../assets/ratesbanner.jpeg";
//Icons
import cleaning from "../assets/cleaning.png";
import shovel from "../assets/shovel.png";
import cottage from "../assets/cottage.png";
import garbage from "../assets/garbage.png";
import CheckIcon from "@material-ui/icons/Check";
import equipicon from "../assets/equipmenticon.png";
import insuranceicon from "../assets/insuranceicon.png";
import fairpricesicon from "../assets/fairpricesicon.png";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  main: {
    alignItems: "center",
    marginBottom: "6em",
  },
  heroImage: {
    marginTop: "-8em",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${banner})`,
    minHeight: "60em",
    width: "100%",
    backgroundPosition: "right top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "30em",
      marginTop: "-3em",
    },
  },
  heroTextContainer: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
  header: {
    fontFamily: "Fjalla One",
    fontSize: "4em",
    color: "#ffffff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  heroBlock: {
    width: "75%",
    backgroundColor: "#ffffff",
    marginTop: "-0.5em",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heroContent: {
    width: "80%",
    alignSelf: "center",
    marginTop: "5em",
    marginBottom: "10em",
  },
  heroHeader: {
    fontWeight: "bolder",
    fontFamily: "Fjalla One",
    fontSize: "1.7em",
    marginBottom: "2em",
    [theme.breakpoints.down("sm")]: {
      lineSpacing: "2px",
    },
  },
  heroSubHeader: {
    fontFamily: "Montserrat",
    color: theme.palette.common.madawaskaBlue,
    fontWeight: "bold",
    marginBottom: "1em",
    textAlign: "center",
    padding: "2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5em",
      padding: "0.5em",
    },
  },
  heroBlockServices: {
    marginTop: "2em",
  },
  servicesRow: {
    marginBottom: "2.5em",
  },
  servicesIcon: {
    width: "125px",
    height: "125px",
    marginRight: "2.5em",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: "1em",
    },
  },
  servicesHeader: {
    fontFamily: "Fjalla One",
    marginBottom: "0.2em",
    fontSize: "1.4em",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  servicesDescription: {
    fontFamily: "Montserrat",
    textAlign: "left",
    marginTop: "0.5em",
    marginBottom: "1em",
    fontSize: "1.3em",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  button: {
    marginTop: "2em",
    marginBottom: "3em",
    padding: "0.6em",
    fontSize: "1.1em",
    color: "white",
    borderRadius: 15,
    borderColor: theme.palette.common.madawaskaBlue,
    borderWidth: 0.5,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: "#e8ae4a",
    },
  },
  whyUsContainer: {
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      borderRadius: "10px",
    },
  },
  whyUsBlock: {
    position: "absolute",
    width: "70%",
    top: "15%",
    left: "0%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      top: "5%",
      padding: "1em",
      marginBottom: "1em",
    },
  },
  whyUsBanner: {
    height: "45em",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "105em",
      objectFit: "none",
    },
  },
  whyUsHeader: {
    fontFamily: "Fjalla One",
    fontSize: "3em",
    color: "#ffffff",
    marginRight: "2.5em",
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  whyUsSubBlock: {
    width: "30%",
    padding: "1em",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      alignSelf: "center",
      marginBottom: "1.5em",
    },
  },
  whyUsIconBlock: {
    marginTop: "5em",
    [theme.breakpoints.down("md")]: {
      marginTop: "1em",
    },
  },
  whyUsIcon: {
    alignSelf: "center",
    minWidth: "150px",
    width: "150px",
    minHeight: "150px",
    height: "150px",
    filter: "drop-shadow(8px 8px 10px gray);",
    transition: "transform .8s;",
    "&:hover": {
      transform: "scale(1.2);",
    },
  },

  whyUsIconHeader: {
    marginTop: "1.2em",
    fontFamily: "Josefin Sans",
    fontSize: "1.6em",
    color: "#ffffff",
    fontWeight: "bolder",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8em",
    },
  },
  whyUsIconDesc: {
    fontFamily: "Montserrat",
    fontSize: "1.3em",
  },
  servicesDetailContainer: {
    width: "75%",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  servicesDetailBlock: {
    width: "65%",
    marginTop: "8em",
    marginBottom: "8em",
  },
  servicesHeaderLarge: {
    fontFamily: "Fjalla One",
    fontSize: "2.5em",
    marginTop: "2em",
    marginBottom: "0.5em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.2em",
      padding: "0.5em",
    },
  },
  servicesImage: {
    minWidth: "80%",
    width: "80%",
    minHeight: "500px",
    height: "500px",
    marginRight: "2em",
    marginLeft: "2em",
  },
  servicesHighlightImage: {
    width: "100%",
    minWidth: "100%",
    height: "900px",
    minHeight: "900px",
    [theme.breakpoints.down("sm")]: {
      height: "400px",
      minHeight: "400px",
    },
  },
  servicesDescriptionContainer: {
    marginBottom: "4em",
    alignSelf: "flex-end",
    width: "80%",

    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  checkIcon: {
    color: "#db941d",
    width: "40px",
    height: "40px",
    marginRight: "0.5em",
  },
  serviceBulletPoint: {
    fontFamily: "Montserrat",
    size: "2.5em",
    marginTop: "0.5em",
    fontSize: "1.2em",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  ratesImage: {
    marginTop: "-1em",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${ratesbanner})`,
    minHeight: "55em",
    width: "100%",
    backgroundPosition: "left top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-0.2em",
      minHeight: "80em",
      backgroundPosition: "right top",
    },
  },
  ratesTextContainer: {
    textAlign: "center",
    position: "absolute",
    width: "40%",
    top: "55%",
    left: "75%",
    transform: "translate(-50%, -50%)",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      left: "50%",
      top: "45%",
    },
  },
}));

export default function Services({ value, setValue }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
    setValue(1);
  }, []);
  return (
    <Grid
      container
      direction="column"
      align="center"
      justifyContent="center"
      className={classes.main}
    >
      <div className={classes.heroImage}>
        <div className={classes.heroTextContainer}>
          <Typography
            variant="h4"
            className="heroText"
            style={{ fontFamily: "Fjalla One", fontSize: "4em" }}
          >
            Pioneer's Services
          </Typography>
        </div>
      </div>
      <Grid
        item
        container
        direction="column"
        className={classes.heroBlock}
        align="center"
      >
        <Grid item container direction="column" className={classes.heroContent}>
          <Typography className={classes.heroHeader}>
            Pioneer Property Management is bringing premium cleaning and
            property management services to the Madawaska Valley. From our
            expertly trained and experienced crew, to our professional equipment
            - we have what it takes to get the job done right!
          </Typography>
          <Typography variant="h5" className={classes.heroSubHeader}>
            We are so much more than a cleaning service. We also do landscaping,
            property management, and fallen tree and large item removal. Call
            today to see how we can help your property reach its full potential!
          </Typography>

          <Grid
            item
            container
            direction="column"
            className={classes.heroBlockServices}
          >
            {matchesSM && (
              <Grid item style={{ marginBottom: "6em" }}>
                <Grid item container direction="row" justifyContent="center">
                  <PhoneIcon
                    style={{
                      margin: "0.2em",
                      color: theme.palette.common.madawaskaBlue,
                    }}
                  />
                  <Typography variant="h4">
                    <a
                      href="tel:+(613)-281-4133"
                      style={{
                        color: theme.palette.common.madawaskaBlue,
                        fontSize: "1em",
                      }}
                    >
                      Call Now - (613)-281-4133
                    </a>
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    alignSelf: "center",
                    width: matchesSM ? "90%" : "25%",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    component={Link}
                    to={"/contact"}
                  >
                    Submit a Service Inquiry
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              direction={matchesSM ? "column" : "row"}
              className={classes.servicesRow}
            >
              <Grid item style={{ width: matchesSM ? "100%" : "30%" }}>
                <img src={cleaning} className={classes.servicesIcon} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={classes.servicesDescriptionBlock}
                style={{ width: matchesSM ? "100%" : "70%" }}
              >
                <Typography variant="h5" className={classes.servicesHeader}>
                  Residential and Commercial Cleaning
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.servicesDescription}
                >
                  Pioneer's expertly trained and experienced cleaning crew will
                  arrive at your property with professional equipment and will
                  clean to the highest standard
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction={matchesSM ? "column" : "row"}
              className={classes.servicesRow}
            >
              <Grid item style={{ width: matchesSM ? "100%" : "30%" }}>
                <img src={shovel} className={classes.servicesIcon} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: matchesSM ? "100%" : "70%" }}
              >
                <Typography variant="h5" className={classes.servicesHeader}>
                  Professional Landscaping and Outdoor Maintenance
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.servicesDescription}
                >
                  The outside of your property should look as good as the
                  inside! Let Pioneer give your lawn the care it deserves. From
                  grass cutting to weeding, Pioneer does it all!
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction={matchesSM ? "column" : "row"}
              className={classes.servicesRow}
            >
              <Grid item style={{ width: matchesSM ? "100%" : "30%" }}>
                <img src={cottage} className={classes.servicesIcon} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: matchesSM ? "100%" : "70%" }}
              >
                <Typography variant="h5" className={classes.servicesHeader}>
                  Property Management Including Cottage Opening and Closing
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.servicesDescription}
                >
                  Keep your cottage experience stress-free by letting Pioneer
                  handle the setup and closing of your cottage. Arrive to a
                  cottage fully ready for you to enjoy!
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction={matchesSM ? "column" : "row"}
              className={classes.servicesRow}
            >
              <Grid item style={{ width: matchesSM ? "100%" : "30%" }}>
                <img src={garbage} className={classes.servicesIcon} />
              </Grid>
              <Grid
                item
                container
                direction="column"
                style={{ width: matchesSM ? "100%" : "70%" }}
              >
                <Typography variant="h5" className={classes.servicesHeader}>
                  Fallen Tree, Debris, and Garbage Removal
                </Typography>
                <Typography
                  variant="h6"
                  className={classes.servicesDescription}
                >
                  Fallen trees cluttering your property? Unused appliances or
                  large items taking up valuable storage space in your home?
                  Pioneer takes care of removing trees and other large items
                  from your property.
                </Typography>
              </Grid>
            </Grid>
            {!matchesSM && (
              <Grid
                item
                style={{
                  alignSelf: "center",
                  width: matchesMD ? "75%" : "30%",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  component={Link}
                  to={"/contact"}
                >
                  Submit a Service Inquiry
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="column"
          className={classes.whyUsContainer}
        >
          <img
            alt="orange banner with spray bottle"
            src={whyusbanner}
            className={classes.whyUsBanner}
          />
          <Grid
            item
            container
            direction="column"
            className={classes.whyUsBlock}
          >
            <Typography
              className={classes.whyUsHeader}
              style={{ alignSelf: "center" }}
            >
              Why Choose Pioneer?
            </Typography>
            <Grid
              item
              container
              direction={matchesMD ? "column" : "row"}
              className={classes.whyUsIconBlock}
            >
              <Grid
                item
                container
                direction="column"
                className={classes.whyUsSubBlock}
                align="center"
              >
                <img
                  src={equipicon}
                  alt="Blue icon with cleaning supplies"
                  className={classes.whyUsIcon}
                />
                <Typography className={classes.whyUsIconHeader}>
                  Professional Equipment
                </Typography>
                <Typography className={classes.whyUsIconDesc}>
                  No need to get out your vaccuum cleaner, we bring all of our
                  own equipment to the job!
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={classes.whyUsSubBlock}
                align="center"
              >
                <img
                  src={insuranceicon}
                  alt="Blue icon with shield symbol"
                  className={classes.whyUsIcon}
                />
                <Typography className={classes.whyUsIconHeader}>
                  Fully Insured
                </Typography>
                <Typography className={classes.whyUsIconDesc}>
                  Our cleaning crew is fully insured so you can welcome them
                  into your home with peace of mind.
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                className={classes.whyUsSubBlock}
                justifyContent="center"
                align="center"
              >
                <img
                  src={fairpricesicon}
                  alt="Blue icon with dollar sign"
                  className={classes.whyUsIcon}
                />
                <Typography className={classes.whyUsIconHeader}>
                  Fair Rates
                </Typography>
                <Typography className={classes.whyUsIconDesc}>
                  We offer our services at a fair price that matches your
                  highest possible standards of clean.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="column"
          alignContent="center"
          justifyContent="center"
          align="center"
        >
          <Grid item>
            <img
              src={cleanbedroom}
              alt="A clean and organized master bedroom"
              className={classes.servicesHighlightImage}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.servicesHeaderLarge}>
              Residential Cleaning Services
            </Typography>
            <Typography variant="h5" className={classes.heroSubHeader}>
              One-time or recurring home cleaning services scheduled around your
              needs
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.servicesDescriptionContainer}
          >
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Vacuuming floors and carpets
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Scrubbing and mopping floors
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Emptying and sanitizing garbage bins
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cleaning and organizing in the home or garage
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cleaning and sanitizing surfaces around the home
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Dusting surfaces and furniture
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cleaning and sanitizing bathrooms including sinks, toilets,
                showers, and mirrors
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cleaning appliances
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Additional services as discussed
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <img
              src={landscape}
              alt="A well manicured lawn"
              className={classes.servicesHighlightImage}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.servicesHeaderLarge}>
              Professional Lawncare and Landscaping
            </Typography>
            <Typography variant="h5" className={classes.heroSubHeader}>
              One-time or recurring lawn care and maintenance to optimize your
              outdoor space
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.servicesDescriptionContainer}
          >
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Grass cutting
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Removal of weeds from gardens and around buildings
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Removal of fallen or dying trees
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Other yard detailing as discussed
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <img
              src={propertymanagement}
              alt="A cottage on the lake"
              className={classes.servicesHighlightImage}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.servicesHeaderLarge}>
              Property Management
            </Typography>
            <Typography variant="h5" className={classes.heroSubHeader}>
              Pioneer knows the importance of maintaining your property
              year-round. That's why we work to ensure your residential or
              commercial property is safe and enjoyable for you and your loved
              ones.
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.servicesDescriptionContainer}
          >
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cottage start up for spring - turning on electric and water
                services
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Cottage shut down for winter - turning off utilities, preparing
                plumbing for winter
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Move ins - cleaning your new home prior to your arrival
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Move outs - clearing out garbage and preparing property for
                listing
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Snow removal from roof and driveway
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start">
              <CheckIcon className={classes.checkIcon} />
              <Typography className={classes.serviceBulletPoint}>
                Other property management services as discussed
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <img
            src={modernkitchen}
            alt="A modern and clean kitchen with stainless steel appliances"
            className={classes.servicesHighlightImage}
          />
        </Grid>
        <Grid item container direction="column">
          <div className={classes.ratesImage}>
            <div className={classes.ratesTextContainer}>
              <Typography
                variant="h4"
                className="heroText"
                style={{ fontFamily: "Fjalla One", fontSize: "4em" }}
              >
                Our Rates
              </Typography>
              <Typography
                variant="h5"
                className="heroText"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "1.5em",
                  marginTop: "2.5em",
                }}
              >
                We stand behind the quality of our clean, therefore we allow a{" "}
                <span style={{ textDecoration: "underline" }}>
                  3 hour minimum
                </span>{" "}
                for residential cleaning services. Our first visit will prepare
                your property for regular maintenance.
              </Typography>
              <Typography
                variant="h5"
                className="heroText"
                style={{
                  fontFamily: "Sriracha",
                  fontSize: "1.5em",
                  color: "#f6e501",
                  fontWeight: "bolder",
                  marginTop: "3em",
                }}
              >
                For <span style={{ color: "#ffffff" }}>$270</span>, you get
                Pioneer's team of cleaning professionals for 3 hours
              </Typography>
              <Typography
                variant="h6"
                className="heroText"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "1.3em",
                  color: "#ffffff",
                  marginTop: "3em",
                }}
              >
                The amount of time we need to service your home depends on the
                size of your home and the services required. Please contact us
                to request a quote.
              </Typography>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                style={{ marginTop: "2em" }}
              >
                <PhoneIcon
                  style={{
                    margin: "0.2em",
                    color: "#ffffff",
                  }}
                />
                <Typography variant="h4">
                  <a href="tel:+(613)-281-4133" style={{ color: "#ffffff" }}>
                    Call Now - (613)-281-4133
                  </a>
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                component={Link}
                to={"/contact"}
              >
                Submit a Service Inquiry
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
