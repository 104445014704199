import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import emailjs from "emailjs-com";
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  FormHelperText,
  Button,
  Typography,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";

//Images
import banner from "../assets/contactbanner.jpg";
import sign from "../assets/sign.jpg";
//Icons
import FacebookIcon from "@material-ui/icons/Facebook";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  main: {
    alignItems: "center",
    marginBottom: "6em",
  },
  heroImage: {
    marginTop: "-8em",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)), url(${banner})`,
    minHeight: "58em",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "30em",
      marginTop: "-3em",
    },
  },
  heroTextContainer: {
    textAlign: "center",
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
  },
  header: {
    fontFamily: "Fjalla One",
    fontSize: "4em",
    color: "#ffffff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  heroBlock: {
    width: "75%",
    backgroundColor: "#ffffff",
    marginTop: "-0.5em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heroContent: {
    width: "80%",
    alignSelf: "center",
    marginTop: "5em",
    marginBottom: "3em",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      marginBottom: "3em",
    },
  },
  heroHeader: {
    fontWeight: "bolder",
    fontFamily: "Fjalla One",
    fontSize: "1.7em",
    marginBottom: "2em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4em",
      lineSpacing: "2px",
    },
  },
  heroSubHeader: {
    fontFamily: "Montserrat",
    color: theme.palette.common.madawaskaBlue,
    fontWeight: "bold",
    marginBottom: "6em",
    textAlign: "center",
    fontSize: "1.5em",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em",
      marginBottom: "3em",
    },
  },
  formContainer: {
    alignSelf: "center",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      marginTop: "1.5em",
      width: "90%",
    },
  },
  button: {
    marginTop: "2em",
    marginBottom: "3em",
    padding: "0.6em",
    fontSize: "1.1em",
    color: "white",
    borderRadius: 15,
    borderColor: theme.palette.common.madawaskaBlue,
    borderWidth: 0.5,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: "#e8ae4a",
    },
    width: "50%",
    alignSelf: "center",
  },
  label: {
    backgroundColor: "white",
    padding: 0,
  },
  formTitle: {
    fontFamily: "Fjalla One",
    fontSize: "2em",
    textAlign: "center",
    marginBottom: "1em",
  },
  featureImage: {
    // minWidth: "100%",
    width: "95%",
    // minHeight: "600px",
    // height: "600px",
    
    [theme.breakpoints.down("sm")]: {
      aspectRatio: "2/1",
    },
  },
}));

export default function Contact({ value, setValue }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameStatus, setNameStatus] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const [messageStatus, setMessageStatus] = useState("");
  const [formStatus, setFormStatus] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setValue(2);
  }, []);
  const validateForm = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let valid = name !== "" && email !== "" && re.test(email) && message !== "";
    setNameStatus(name === "" ? "Enter your name" : "");

    setEmailStatus(
      email === "" || !re.test(email) ? "Enter a valid email address" : ""
    );

    setMessageStatus(message === "" ? "Enter your message" : "");

    return valid;
  };

  const submitForm = () => {
    //validate the form
    if (!validateForm()) {
      setFormStatus("Fill out required fields and try again");
    } else {
      //Send the email
      var templateParams = {
        from_name: name,
        reply_to: email,  
        message_html: message,
      };

      emailjs
        .send(
          "gmail",
          "PioneerPropertyManagement",
          templateParams,
          "user_SdhrQXfrPZb4mn1plcyk9"
        )
        .then(
          (result) => {
            setFormStatus("Thank you! Talk to you soon.");
            setName("");
            setEmail("");
            setMessage("");
          },
          (error) => {
            setFormStatus(
              "There was a problem submitting the form. Try again or call me directly at (613)-281-4133"
            );
          }
        );
    }
  };

  return (
    <Grid
      container
      direction="column"
      align="center"
      justifyContent="center"
      className={classes.main}
    >
      <div className={classes.heroImage}>
        <div className={classes.heroTextContainer}>
          <Typography
            variant="h4"
            className="heroText"
            style={{ fontFamily: "Fjalla One", fontSize: "4em" }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="h6"
            className="heroSubText"
            style={{
              fontFamily: "Montserrat",
              fontSize: matchesSM ? "1.6em" : "2em",
              marginTop: matchesSM ? "1.5em" : "0.5em",
            }}
          >
            We Would Love To Hear From You!
          </Typography>
        </div>
      </div>
      <Grid
        item
        container
        direction="column"
        className={classes.heroBlock}
        align="center"
        justifyContent="center"
      >
        <Grid item container direction="column" className={classes.heroContent}>
          <Typography className={classes.heroHeader}>
            To request a free quote or to inquire about our services, call us at
            <span>
              {" "}
              <a
                href="tel:+(613)-281-4133"
                style={{
                  textDecoration: "none",
                  color: theme.palette.common.madawaskaBlue,
                }}
              >
                (613)-281-4133
              </a>
            </span>{" "}
            or fill out the form below. We will get back to you as soon as we
            can!
          </Typography>
          <Typography variant="h5" className={classes.heroSubHeader}>
            Please include an email that you can be reached at and a description
            of your requested services in the message box
          </Typography>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.formTitle}>
                Contact Pioneer Property Management
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              align="center"
              justifyContent="center"
              className={classes.formContainer}
            >
              <TextField
                type="text"
                value={name}
                variant="outlined"
                name="name"
                onChange={(e) => setName(e.target.value)}
                label="Your Name"
                autoFocus={true}
                required
                error={nameStatus !== ""}
                helperText={nameStatus}
                style={{
                  width: "100%",
                  marginBottom: "1.5em",
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />

              <TextField
                type="text"
                variant="outlined"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                label="Email Address"
                required
                value={email}
                error={emailStatus !== ""}
                helperText={emailStatus}
                style={{ width: "100%", marginBottom: "1.5em" }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />

              <TextField
                type="text"
                variant="outlined"
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                label="Message"
                required
                value={message}
                error={messageStatus !== ""}
                helperText={messageStatus}
                style={{ width: "100%", marginBottom: "1em" }}
                multiline={true}
                rowsMax={50}
                rows={5}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
              />

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={submitForm}
              >
                Submit
              </Button>
              <Typography variant="h6" className={classes.heroSubHeader}>
                {formStatus}
              </Typography>
            </Grid>

            <Grid item>
              <img
                src={sign}
                alt="Pioneer Property Management Sign"
                className={classes.featureImage}
              />
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              style={{
                marginTop: "5em",
                color: theme.palette.common.madawaskaBlue,
              }}
            >
              <FacebookIcon
                style={{ marginRight: "0.2em", width: "45px", height: "45px" }}
              />
              <Typography variant="h6">
                <a
                  href="https://www.facebook.com/bruce.ashford.75"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.5em",
                    marginTop: "1.2em",
                  }}
                >
                  Find Us On Facebook!
                </a>
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              style={{ marginTop: "2em" }}
            >
              <PhoneIcon
                style={{
                  marginRight: "0.2em",
                  width: "40px",
                  height: "40px",
                  color: theme.palette.common.madawaskaBlue,
                }}
              />
              <Typography variant="h6">
                <a
                  href="tel:+(613)-281-4133"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "1.5em",
                  }}
                >
                  Call Now - (613)-281-4133
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
