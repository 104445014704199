import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Modal,
  Backdrop,
  Fade,
  FormControl,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Carousel from "react-material-ui-carousel";
import ImageUploader from "react-images-upload";
import "../App.css";
import { db, imagesRef } from "../firebaseConfig";
import emailjs from "emailjs-com";
import PulseLoader from "react-spinners/PulseLoader";

/*Images*/
import cleanhouse from "../assets/cleanhouse.jpg";
import cleanhouse2 from "../assets/cleanhouse2.jpg";
import landscape from "../assets/landscape.jpg";
import treeremoval from "../assets/treeremoval.jpg";
import cottage from "../assets/cottage.jpg";
import garage from "../assets/organizedgarage.jpg";

//Icons
import greenClean from "../assets/greenCleaning.png";
import janitorial from "../assets/janitorial.png";
import satisfaction from "../assets/satisfaction.png";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import Testimonial from "./Testimonial";

const useStyles = makeStyles((theme) => ({
  main: {
    alignItems: "center",
    marginBottom: "6em",
  },
  heroImage: {
    marginTop: "-5em",
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${cleanhouse2})`,
    minHeight: "60em",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: "30em",
      marginTop: "-3em",
    },
  },
  heroTextContainer: {
    textAlign: "center",
    position: "absolute",
    width: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
  },

  heroLinkText: {
    textDecoration: "none",
    color: theme.palette.common.goldenOrange,
    fontSize: "1.6em",
    fontFamily: "Fjalla One",
  },
  cardRoot: {
    borderRadius: 10,
    width: "20em",
    height: "10em",
    position: "absolute",
    right: "1.5em",
    top: "9em",
  },
  cardHeaderBackground: {
    backgroundColor: theme.palette.common.madawaskaBlue,
  },
  cardHeaderText: {
    fontFamily: "Sriracha",
    fontSize: "1.2em",
    textAlign: "center",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    color: "white",
  },
  requestText: {
    textAlign: "center",
    color: theme.palette.common.madawaskaBlue,
  },
  phoneNumber: {
    textAlign: "center",
    fontWeight: "bolder",
    marginTop: "0.5em",
    color: theme.palette.common.darkBrown,
  },
  heroBlock: {
    backgroundColor: "white",
    zIndex: 100,
    width: "75%",
    marginTop: "-5em",
    borderRadius: "10px",
    marginBottom: "1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heroHeader: {
    marginTop: "1.5em",
    padding: "0.5em",
    marginBottom: "1em",
    fontFamily: "Fjalla One",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1em",
    },
  },
  heroSubHeader: {
    fontFamily: "Montserrat",
    color: theme.palette.common.madawaskaBlue,
    fontWeight: "bold",
    marginBottom: "2em",
    textAlign: "center",
    padding: "0.5em",
  },
  heroPara: {
    fontFamily: "Montserrat",
    width: "80%",
    fontSize: "1.1em",
    textAlign: "justify",
    marginBottom: "3em",
    padding: "0.5em",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      textAlign: "center",
    },
  },
  carouselContainer: {
    minWidth: "100%",
    width: "100%",
    minHeight: "40em",
    height: "40em",
    marginTop: "2em",
    marginBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  slideShowImage: {
    height: "45em",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "20em",
      maxHeight: "20em",
      minHeight: "20em",
    },
  },
  slideshowText: {
    textAlign: "center",
    marginLeft: "1em",
    fontSize: "1.3em",
    fontFamily: "Montserrat",
    padding: "1em",
  },
  serviceHighlightContainer: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: "8em",
    [theme.breakpoints.down("sm")]: {
      marginTop: "3em",
    },
  },
  serviceHighlightBlock: {
    width: "33%",
    marginBottom: "2em",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "5em",
    },
  },
  serviceHighlightImage: {
    alignSelf: "center",
    marginTop: "2.5em",
  },
  serviceDescription: {
    fontFamily: "Montserrat",
    fontSize: "1.2em",
    marginLeft: "4em",
    marginRight: "4em",
    marginTop: "0.5em",
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      marginLeft: "1em",
      marginRight: "1em",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2em",
      marginRight: "2em",
      textAlign: "center",
    },
  },
  serviceHighlightTitle: {
    fontFamily: "Fjalla One",
    fontWeight: "bolder",
    color: "black",
    fontSize: "1.8em",
  },
  serviceHighlightPara: {
    marginTop: "0.5em",
    marginBottom: "3em",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: "8em",
    marginRight: "8em",
    padding: "1em",
    [theme.breakpoints.down("md")]: {
      marginLeft: "4em",
      marginRight: "4em",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0,
      marginBottom: "1em",
    },
  },
  button: {
    width: "300px",
    alignSelf: "center",
    marginTop: "2em",
    marginBottom: "3em",
    padding: "0.6em",
    fontSize: "1.1em",
    color: "white",
    borderRadius: 15,
    borderColor: theme.palette.common.madawaskaBlue,
    borderWidth: 0.5,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: "#e8ae4a",
    },
  },
  locationImg: {
    height: "55vh",
    width: "55vh",
    margin: 0,
    [theme.breakpoints.down("lg")]: {
      width: "50vh",
      height: "50vh"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "60vh"
    },
  },
  locationText: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    color: "#ffffff",
    backgroundColor: theme.palette.common.madawaskaBlue,
    padding: "0.5em",
    fontFamily: "Sriracha",
    fontSize: "1.2em",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1em",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #1e6cbb",
    boxShadow: theme.shadows[5],
    padding: "2em",
    paddingTop: "3em",
    width: "50%",
    minWidth: "50%",
    height: "70%",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      minWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },
  },
  label: {
    backgroundColor: "white",
    padding: 0,
  },
}));

export default function Welcome({ value, setValue }) {
  const [reviews, setReviews] = useState([]);
  const [name, setName] = useState("");
  const [rating, setRating] = useState(0);
  const [reviewMessage, setReviewMessage] = useState("");
  const [reviewPhotos, setReviewPhotos] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));
  const carouselItems = [
    {
      alt: "a spotless kitchen",
      src: cleanhouse,
      description:
        "Residential/Commercial Cleaning - Let Pioneer Clean Your Property Until It Shines!",
    },
    {
      alt: "a professionally landscaped yard",
      src: landscape,
      description:
        "Professional Landscaping - Get The Perfectly Landscaped Lawn You've Always Dreamed Of!",
    },
    {
      alt: "cutting a fallen tree with a chainsaw",
      src: treeremoval,
      description:
        "Fallen Tree and Debris Removal - Help Your Property Look It's Best By Removing Fallen Trees and Other Large Debris",
    },
    {
      alt: "two chairs on a deck overlooking a lake",
      src: cottage,
      description:
        "Cottage Start-Up/Shut-Down - Visiting the Cottage Should Be Stress-Free! Let Pioneer Handle The Maintenance Work So You Can Just Sit Back and Enjoy!",
    },
    {
      alt: "an organized garage",
      src: garage,
      description:
        "Garage and Home Organization - Let Pioneer Help You Ensure Everything In Your Home and Garage is Neat and Tidy so You Can Get Back to Enjoying Your Property!",
    },
  ];
  function CarouselItem(props) {
    return (
      <div>
        <img
          alt={props.alt}
          src={props.src}
          className={classes.slideShowImage}
        />
        <p className={classes.slideshowText}>{props.description}</p>
      </div>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchReviews();
  }, []);

  const toggleReviewModal = (show) => {
    setShowReviewModal(show);
  };

  const fetchReviews = () => {
    let reviews = [];
    console.dir(db);
    let ref = db.collection("/reviews");
    ref
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          let d = doc.data();
          d.id = doc.id;
          if (d.live === true) reviews.push(d);
        });
        setReviews(reviews);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postReview = async () => {
    if (name === "" || rating === 0 || reviewMessage === "") {
      setModalMessage("Fill out all fields and try again");
      return;
    }
    setShowLoader(true);
    var imageUrls = [];
    //if there are photos, upload them to storage
    if (reviewPhotos.length > 0) {
      imageUrls = await uploadPhotos();
    }

    let review = {
      name: name,
      rating: rating,
      review: reviewMessage,
      live: false,
      photos: imageUrls,
    };
    db.collection("/reviews")
      .add(review)
      .then((ref) => {
        if (ref.id !== undefined) {
          //Send the email
          var templateParams = {
            from_name: name,
            rating: rating,
            review: reviewMessage,
          };

          emailjs
            .send(
              "gmail",
              "new_review",
              templateParams,
              "user_SdhrQXfrPZb4mn1plcyk9"
            )
            .then((result) => {
              setShowLoader(false);
              setModalMessage("Thank you for your review! Check back soon");
            })
            .catch((err) => {
              setShowLoader(false);
              setModalMessage("Error submitting review, please try again");
              console.log(err);
            });
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setModalMessage("Error submitting review, please try again");
        console.log(err);
      });
  };

  const uploadPhotos = async () => {
    var imageUrls = [];
    try {
      let refs = [];
      reviewPhotos.forEach((photo) => {
        refs.push(imagesRef.child(`${photo.name}`));
      });
      for (var i = 0; i < refs.length; i++) {
        let result = await refs[i].put(reviewPhotos[i]);
        let url = await refs[i].getDownloadURL();
        imageUrls.push(url);
      }
      return imageUrls;
    } catch (err) {
      console.log(err);
    }
  };

  const onDropPhoto = (photos) => {
    setReviewPhotos(photos);
  };
  return (
    <Grid container direction="column" className={classes.main}>
      <div className={classes.heroImage}>
        <div className={classes.heroTextContainer}>
          {!matchesSM && (
            <Typography
              variant="h4"
              className="heroText"
              style={{ fontFamily: "Fjalla One", fontSize: "4em" }}
            >
              Pioneer Property Management
            </Typography>
          )}

          <Typography
            variant="h6"
            className={matchesSM ? "" : "heroSubText"}
            style={{
              fontFamily: "Montserrat",
              fontSize: matchesSM ? "1.6em" : "2em",
              marginTop: matchesSM ? "-2em" : "0.5em",
            }}
          >
            {`Residential/Commercial Cleaning Services and Property
            Management`}
          </Typography>
          {matchesSM && (
            <div style={{ marginTop: "1em" }}>
              <a href="tel:+(613)-281-4133" className={classes.heroLinkText}>
                Call Now - (613)-281-4133
              </a>
            </div>
          )}
        </div>
      </div>
      {!matchesSM && (
        <Grid item>
          <Card variant="elevation" className={classes.cardRoot}>
            <CardContent style={{ padding: 0 }}>
              <Grid item container direction="column">
                <Grid item className={classes.cardHeaderBackground}>
                  <Typography variant="h6" className={classes.cardHeaderText}>
                    Servicing the Madawaska Valley
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.requestText}>
                    To request a{" "}
                    <span style={{ fontWeight: "bold" }}>Free</span> Quote,
                    Please Call:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" className={classes.phoneNumber}>
                    <a
                      href="tel:+(613)-281-4133"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      (613)-281-4133
                    </a>
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  style={{
                    marginTop: "0.5em",
                    color: theme.palette.common.madawaskaBlue,
                  }}
                >
                  <FacebookIcon style={{ marginRight: "0.2em" }} />
                  <Typography variant="h6">
                    <a
                      href="https://www.facebook.com/bruce.ashford.75"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: theme.palette.common.madawaskaBlue,
                      }}
                    >
                      Find Us On Facebook!
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        className={classes.heroBlock}
      >
        <Grid item>
          <Typography variant="h3" className={classes.heroHeader}>
            Pioneer Property Management
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" className={classes.heroSubHeader}>
            We add value to real estate by enhancing the natural beauty of your
            property!
          </Typography>
        </Grid>
        <Grid item className={classes.heroPara}>
          <Typography variant="h5">
            Located in Barry's Bay, Pioneer Property Management provides
            residential and commercial cleaning services to Barry's Bay and
            surrounding area. We offer one-time or recurring cleaning,
            landscaping, and property management services for your home or
            business.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to={"/services"}
          >
            Learn More
          </Button>
        </Grid>
        <Grid item className={classes.carouselContainer}>
          <Carousel
            navButtonsAlwaysVisible={true}
            timeout={1200}
            autoPlay={true}
            interval={10000}
          >
            {carouselItems.map((item, index) => (
              <CarouselItem
                key={index}
                alt={item.alt}
                src={item.src}
                description={item.description}
              />
            ))}
          </Carousel>
        </Grid>
        <Grid
          item
          container
          direction="column"
          align="center"
          className={classes.serviceHighlightContainer}
        >
          <Typography
            variant="h3"
            className={classes.heroHeader}
            style={{ marginTop: 0 }}
          >
            Pioneer Property Management Services
          </Typography>
          <Typography variant="h4" className={classes.heroSubHeader}>
            We Bring Our Own Professional Equipment - Fair Prices - Fully
            Insured
          </Typography>
          <Typography variant="h5" className={classes.serviceHighlightPara}>
            Pioneer Property Management takes care of all the work that needs to
            be done around your property so you can sit back and enjoy! From
            day-to-day cleaning tasks, to landscaping and yard maintenance, to
            cottage setup and closing, we offer a variety of services that will
            make your life easier!
          </Typography>
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            justifyContent="space-evenly"
            style={{ marginTop: "1em" }}
          >
            <Grid
              item
              container
              direction="column"
              className={classes.serviceHighlightBlock}
            >
              <Grid item className={classes.serviceHighlightImage}>
                <img alt="bucket with soapy water" src={greenClean} />
              </Grid>
              <Grid item className={classes.serviceHighlightImage}>
                <Typography
                  variant="h5"
                  className={classes.serviceHighlightTitle}
                >
                  Green Cleaning
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.serviceDescription}>
                  If you want your property cleaned with green cleaning
                  products, just let us know. We are more than happy to
                  accommodate your request!
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="column"
              className={classes.serviceHighlightBlock}
            >
              <Grid item className={classes.serviceHighlightImage}>
                <img alt="recycling bin" src={janitorial} />
              </Grid>
              <Grid item className={classes.serviceHighlightImage}>
                <Typography
                  variant="h5"
                  className={classes.serviceHighlightTitle}
                >
                  Janitorial Services
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.serviceDescription}>
                  Pioneer does all the regular cleaning including vacuuming,
                  mopping, and sanitization. We also do garbage removal,
                  appliance cleaning, and home/garage organization.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="column"
              className={classes.serviceHighlightBlock}
            >
              <Grid item className={classes.serviceHighlightImage}>
                <img alt="squeegy cleaning window" src={satisfaction} />
              </Grid>
              <Grid item className={classes.serviceHighlightImage}>
                <Typography
                  variant="h5"
                  className={classes.serviceHighlightTitle}
                >
                  Satisfaction Guaranteed
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.serviceDescription}>
                  Pioneer is a new startup company so building a happy
                  relationship with our customers is very important to us! We
                  strive to ensure our customers are 100% satisfied with our
                  services.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            style={{ paddingRight: matchesSM ? 0 : "3em", marginTop: "1em" }}
          >
            <PhoneIcon
              style={{
                margin: "0.2em",
                color: theme.palette.common.madawaskaBlue,
              }}
            />
            <Typography variant="h4">
              <a
                href="tel:+(613)-281-4133"
                style={{
                  color: theme.palette.common.madawaskaBlue,
                  fontSize: matchesSM ? "0.9em" : "inherit",
                }}
              >
                Call Now - (613)-281-4133
              </a>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            align="center"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={Link}
              to={"/contact"}
            >
              Submit a Service Inquiry
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={classes.serviceHighlightContainer}
        >
          <Typography
            variant="h3"
            className={classes.heroHeader}
            style={{ marginTop: 0 }}
          >
            Professional Cleaning Service Locations
          </Typography>
          <Typography variant="h5" className={classes.serviceHighlightPara}>
            Pioneer Property Management is located in Barry's Bay, Ontario and
            we proudly offer services to the Madawaska Valley including Barry's
            Bay, Wilno, Madawaska and Killaloe.
          </Typography>
          <Grid
            item
            container
            direction={matchesMD ? "column" : "row"}
            align="center"
            justifyContent="center"
            style={{ marginBottom: "-.3em" }}
          >
            <Grid item style={{ position: "relative" }}>
              <a>
                <img
                  className={classes.locationImg}
                  alt="Barrys Bay Ontario"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Barrys_Bay_Ontario.JPG/512px-Barrys_Bay_Ontario.JPG"
                />
              </a>
              <Typography className={classes.locationText}>
                Barry's Bay
              </Typography>
            </Grid>
            <Grid item style={{ position: "relative" }}>
              <a>
                <img
                  className={classes.locationImg}
                  alt="Wilno Ontario"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Wilno_Ontario.JPG/512px-Wilno_Ontario.JPG"
                />
              </a>
              <Typography className={classes.locationText}>Wilno</Typography>
            </Grid>
            <Grid item style={{ position: "relative" }}>
              <a>
                <img
                  className={classes.locationImg}
                  alt="Killaloe Ontario"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e2/Killaloe_Ontario.JPG/512px-Killaloe_Ontario.JPG"
                />
              </a>
              <Typography className={classes.locationText}>Killaloe</Typography>
            </Grid>
          </Grid>
          {/* <Grid
            item
            container
            direction={matchesSM ? "column" : "row"}
            align="center"
            justify="center"
          >
            <Grid item style={{ position: "relative" }}>
              <a>
                <img
                  className={classes.locationImg}
                  alt="Dacre ON"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Dacre_ON.jpg/512px-Dacre_ON.jpg"
                />
              </a>
              <Typography className={classes.locationText}>
                Madawaska
              </Typography>
            </Grid>
            
          </Grid> */}
        </Grid>
        <Grid
          item
          container
          direction="column"
          className={classes.serviceHighlightContainer}
          style={{ marginBottom: "3em" }}
        >
          <Typography variant="h3" className={classes.heroHeader}>
            See What Our Customers Are Saying
          </Typography>
          <Typography variant="h5" className={classes.heroSubHeader}>
            Want to share your experience with Pioneer Property Management?
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{ marginTop: 0 }}
            onClick={() => toggleReviewModal(true)}
          >
            Post a Review
          </Button>
          {reviews.length > 0 && (
            <Carousel navButtonsAlwaysVisible={true} autoPlay={false}>
              {reviews.map((review, index) => (
                <Testimonial
                  key={index}
                  reviewerName={review.name}
                  value={review.rating}
                  reviewText={review.review}
                  imageUrls={
                    review.photos && review.photos.length > 0
                      ? review.photos
                      : []
                  }
                />
              ))}
            </Carousel>
          )}
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          style={{
            marginTop: "3em",
            color: theme.palette.common.madawaskaBlue,
          }}
        >
          <FacebookIcon
            style={{ marginRight: "0.2em", width: "45px", height: "45px" }}
          />
          <Typography variant="h6">
            <a
              href="https://www.facebook.com/bruce.ashford.75"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.5em",
                marginTop: "1.2em",
              }}
            >
              Find Us On Facebook!
            </a>
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: "2em", marginBottom: "3em" }}
        >
          <PhoneIcon
            style={{
              marginRight: "0.2em",
              width: "45px",
              height: "45px",
              color: theme.palette.common.madawaskaBlue,
            }}
          />
          <Typography variant="h6">
            <a
              href="tel:+(613)-281-4133"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.5em",
              }}
            >
              Call Now - (613)-281-4133
            </a>
          </Typography>
        </Grid>
        <Modal
          aria-labelledby="post review modal"
          aria-describedby="post review modal"
          className={classes.modal}
          open={showReviewModal}
          onClose={() => toggleReviewModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showReviewModal}>
            <div className={classes.paper}>
              <Grid container direction="column">
                <Typography
                  variant="h4"
                  className={classes.heroHeader}
                  style={{ margin: 0, fontSize: "2em" }}
                >
                  Share Your Review
                </Typography>
                <FormControl
                  style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    alignSelf: "center",
                  }}
                >
                  <Rating
                    value={parseInt(rating)}
                    onChange={(e) => setRating(e.target.value)}
                    size="large"
                  />
                  <FormHelperText
                    style={{
                      fontSize: "1em",
                      textAlign: "center",
                      marginBottom: "1.5em",
                    }}
                  >
                    Your Rating
                  </FormHelperText>
                </FormControl>
                <TextField
                  type="text"
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  label="Your Name"
                  autoFocus={true}
                  required
                  style={{
                    width: matchesSM ? "100%" : "80%",
                    marginBottom: "1.5em",
                    alignSelf: "center",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                />

                <TextField
                  type="text"
                  value={reviewMessage}
                  variant="outlined"
                  onChange={(e) => setReviewMessage(e.target.value)}
                  label="Describe Your Experience With Us"
                  required
                  style={{
                    width: matchesSM ? "100%" : "80%",
                    marginBottom: "1.5em",
                    alignSelf: "center",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.label,
                    },
                  }}
                  multiline={true}
                  rows={5}
                  rowsMax={5}
                />
                {!matchesSM && (
                  <div style={{ marginBottom: "3em" }}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      Add Photos To Your Review:
                    </Typography>
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose Images"
                      onChange={onDropPhoto}
                      imgExtension={[
                        ".jpg",
                        ".png",
                        "JPG",
                        "PNG",
                        "JPEG",
                        "jpeg",
                      ]}
                      withLabel={false}
                      maxFileSize={5242880}
                      withPreview={true}
                      fileTypeError={
                        "File extension not supported. Supported types: JPG, PNG"
                      }
                      errorStyle={{ textAlign: "center" }}
                    />
                    <div
                      className="sweet-loading"
                      style={{ textAlign: "center" }}
                    >
                      <PulseLoader
                        size={40}
                        color={theme.palette.common.madawaskaBlue}
                        loading={showLoader}
                      />
                    </div>
                  </div>
                )}

                <Typography
                  variant="h6"
                  className={classes.heroSubHeader}
                  style={{ margin: 0 }}
                >
                  {modalMessage}
                </Typography>
                <Grid
                  item
                  container
                  direction="row"
                  align="flex-end"
                  justifyContent="center"
                  style={{ marginTop: "1em" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={postReview}
                    style={{
                      width: "150px",
                      padding: 5,
                      marginRight: "1em",
                      marginBottom: 0,
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => toggleReviewModal(false)}
                    style={{ width: "150px", padding: 5, marginBottom: 0 }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </Grid>
  );
}
