import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Grid,
  Box,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  Modal,
  Backdrop,
  Fade,
  Button,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
const useStyles = makeStyles((theme) => ({
  cardRoot: {
    padding: "3em",
    marginTop: "1em",
    marginBottom: "1em",
  },
  reviewerNameText: {
    fontSize: "1.5em",
    color: theme.palette.common.madawaskaBlue,
    fontWeight: "bold",
    marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  reviewText: {
    fontSize: "1.3em",
    marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  reviewImage: {
    height: "200px",
    minHeight: "200px",
    width: "200px",
    minWidth: "200px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #1e6cbb",
    boxShadow: theme.shadows[5],
    padding: "2em",
    paddingTop: "3em",
    width: "50%",
    minWidth: "50%",
    height: "80%",
    overflowY: "scroll",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      minWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: "100%",
    },
  },
  button: {
    width: "300px",
    alignSelf: "center",
    marginTop: "2em",
    marginBottom: "3em",
    padding: "0.6em",
    fontSize: "1.1em",
    color: "white",
    borderRadius: 15,
    borderColor: theme.palette.common.madawaskaBlue,
    borderWidth: 0.5,
    borderStyle: "solid",
    "&:hover": {
      backgroundColor: "#e8ae4a",
    },
  },
  reviewImageModal: {
    height: "100%",
    minHeight: "100%",
    width: "100%",
    minWidth: "100%",
  },
}));

export default function Testimonial(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const toggleImageModal = (show) => {
    setShowModal(show);
  };
  return (
    <React.Fragment>
      <Card variant="elevation" className={classes.cardRoot}>
        <CardContent>
          <Grid container direction="column">
            <Typography variant="h6" className={classes.reviewerNameText}>
              {props.reviewerName}
            </Typography>
            <Box
              component="fieldset"
              mb={2}
              borderColor="transparent"
              style={{ marginLeft: matchesSM ? "-1em" : "inherit" }}
            >
              <Rating
                name="read-only"
                value={parseInt(props.value)}
                readOnly
                size="large"
              />
            </Box>
            <Typography className={classes.reviewText}>
              {props.reviewText}
            </Typography>
            <Grid item container direction="row" style={{ marginTop: "2em" }}>
              {props.imageUrls.map((image, i) => (
                <img
                  key={i}
                  src={image}
                  className={classes.reviewImage}
                  onClick={() => {
                    toggleImageModal(true);
                    setSelectedImage(image);
                  }}
                />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Modal
        aria-labelledby="review image modal"
        aria-describedby="review image modal"
        className={classes.modal}
        open={showModal}
        onClose={() => toggleImageModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <div className={classes.paper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => toggleImageModal(false)}
              style={{
                width: "150px",
                padding: 5,
                marginTop: 0,
                marginBottom: "1em",
              }}
            >
              Close
            </Button>
            <img src={selectedImage} className={classes.reviewImageModal} />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
