import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  AppBar,
  Toolbar,
  useScrollTrigger,
  Tabs,
  Tab,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/styles";
import MenuIcon from "@material-ui/icons/Menu";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.modal + 1, //moves drawer below header
    backgroundColor: theme.palette.common.goldenOrange,
    minHeight: "60px",
    height: "60px",
  },
  tabContainer: {
    marginLeft: "auto",
    marginTop: "auto",
    marginRight: "2rem",
  },
  tab: {
    ...theme.typography.tab,
    fontSize: "1.2em",
    minWidth: 10,
    marginLeft: "30px",
    color: "white",
    opacity: 0.8,
  },
  tabSelected: {
    ...theme.typography.tab,
    fontSize: "1.2em",
    minWidth: 10,
    marginLeft: "30px",
    color: "white",
    opacity: 1,
  },
  drawer: {
    backgroundColor: theme.palette.common.goldenOrange,
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: "3em",
    marginTop: "1.5em",
    [theme.breakpoints.down("md")]: {
      marginBottom: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1.25em",
    },
  },
  drawerItem: {
    ...theme.typography.drawerItem,

    opacity: 0.7,
  },
  drawerItemSelected: {
    ...theme.typography.drawerItem,
    opacity: 1,
  },
  drawerIconContainer: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginLeft: "auto",
  },
  drawerIcon: {
    height: "60px",
    width: "60px",
    color: "#ffffff",
  },
}));

export default function Navigation({ value, setValue }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);

  const menuOptions = [
    { name: "About Us", link: "/", activeIndex: 0 },
    { name: "What We Do", link: "/services", activeIndex: 1 },
    { name: "Contact", link: "/contact", activeIndex: 2 },
  ];

  useEffect(() => {
    menuOptions.forEach((route) => {
      switch (window.location.pathname) {
        case `${route.link}`:
          if (value !== route.activeIndex) {
            setValue(route.activeIndex);
          }
          break;
        default:
          break;
      }
    });
  }, [value, setValue, menuOptions]);

  const handleTabChange = (e, newValue) => {
    setValue(newValue);
  };

  const tabs = (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleTabChange}
        className={classes.tabContainer}
        indicatorColor="secondary"
      >
        {menuOptions.map((option, index) => {
          return (
            <Tab
              key={option.name}
              className={value === index ? classes.tabSelected : classes.tab}
              component={Link}
              to={option.link}
              label={option.name}
            />
          );
        })}
      </Tabs>
    </React.Fragment>
  );

  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
        anchor="right"
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          {menuOptions.map((option, index) => (
            <ListItem
              key={option.name}
              onClick={() => {
                setOpenDrawer(false);
                setValue(index);
              }}
              button
              divider
              component={Link}
              to={option.link}
              selected={value === index}
            >
              <ListItemText
                disableTypography
                className={
                  value === index
                    ? [classes.drawerItem, classes.drawerItemSelected].join(" ")
                    : classes.drawerItem
                }
              >
                {option.name}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              <Grid item style={{ alignSelf: "flex-end" }}>
                {matchesSM ? drawer : tabs}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin} />
    </React.Fragment>
  );
}
