import { createTheme } from "@material-ui/core/styles";

const lightGray = "#D4D4D4";
const madawaskaBlue = "#1e6cbb";
const goldenOrange = "#db941d";

export default createTheme({
  palette: {
    common: {
      goldenOrange: goldenOrange,

      lightGray: lightGray,

      madawaskaBlue: madawaskaBlue,
    },
    primary: {
      main: goldenOrange,
    },
    secondary: {
      main: lightGray,
    },
  },
  typography: {
    drawerItem: {
      fontFamily: "Roboto, Helvetica, Arial",
      paddingTop: "1em",
      paddingLeft: "2em",
      paddingRight: "2em",
      color: "white",
    },
    h6: {
      fontSize: "1.1em",
    },
    h5: {
      fontSize: "1.3em",
      fontFamily: "Montserrat",
    },
    h4: {
      fontSize: "1.6em",
      fontWeight: "bold",
      fontStyle: "italic",
    },
  },
});
