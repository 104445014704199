import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


const firebaseConfig = {
  apiKey: "AIzaSyD7_yUyY3W5uEOubs139aalohq0o_tjs58",
  authDomain: "pioneer-property-managem-acafe.firebaseapp.com",
  databaseURL: "https://pioneer-property-managem-acafe.firebaseio.com",
  projectId: "pioneer-property-managem-acafe",
  storageBucket: "pioneer-property-managem-acafe.appspot.com",
  messagingSenderId: "836139893946",
  appId: "1:836139893946:web:a8c80735903ce21afdee83",
  measurementId: "G-9GEVVZ0JEW",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
// export const db = getFirestore(app);
export const imagesRef = firebase.storage().ref();
// export const imagesRef = getStorage(app);
